<template>
  <li
    class="collapsible-list-item"
    :class="{ 'collapsible-list-item--visible': visible }"
  >
    <div
      v-b-toggle="`cl-item-${clId}`"
      class="collapsible-list-item__header"
    >
      <div class="collapsible-list-item__icon">
        <svg-icon name="chevron-right" />
      </div>
      <div class="collapsible-list-item__title">{{ item.name }}</div>
    </div>
    <b-collapse
      v-model="visible"
      :id="`cl-item-${clId}`"
      @show="onShown"
    >
      <div
        v-if="visible"
        class="collapsible-list-item__content"
      >
        <slot />
      </div>
    </b-collapse>
  </li>
</template>

<script>
export default {
  name: 'collapsible-list-item',
  props: {
    item: { type: Object, default: Object },
  },
  data() {
    return {
      clId: this._uid,
      visible: false,
    };
  },
  methods: {
    onShown() {
      this.$emit('shown', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsible-list-item {
  background-color: var(--ca-expnd-bg);
  border-bottom: 1px solid var(--line-color-main);
  transition: 0.3s ease-in-out;
  transition-property: background, border;

  &__header {
    padding: 9px 8px;
    cursor: pointer;
    display: flex;
  }

  &__icon {
    color: var(--secondary);
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    transition-property: transform, color;

    .svg-icon {
      width: 12px;
      height: 12px;
    }
  }

  &--visible &__icon {
    transform: rotate(90deg);
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    align-self: center;
    display: flex;
    font-weight: 500;
  }

  &__content {
    margin-bottom: 30px;
    padding: 0 12px;
  }
}
</style>
