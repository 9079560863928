<template>
  <div
    class="entity-list"
    :class="rootClasses"
  >
    <!-- Header -->
    <entity-list-header
      v-if="isShowHeader"
      :list-views="listViews"
      :custom-views="customViews"
      :items="items"
      :sort="sort"
      :fields="fields"
      :is-mobile="isMobile"
      :is-loading-data="isLoadingContent"
      :entity-list="entityList"
      :is-show-fields-settings="isShowFieldsSettings"
      :header-type="headerType"
      class="entity-list__header"
      @show-modal-settings="showFieldsSettings"
      @change-view="changeView"
      @change-sort="changeSort"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        v-slot:[name]
      >
        <slot :name="name" />
      </template>
    </entity-list-header>

    <!-- Body -->
    <div
      ref="cardBody"
      class="entity-list__body el-body"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="!isEmptyList"
          key="list"
          class="el-body__inner"
        >
          <loading
            v-show="isShowViewLoading"
            height="300"
          />
          <entity-list-view
            v-if="entityList.listDataInited && showViewContent"
            v-show="!isShowViewLoading"
            :items="items"
            :sort="sort"
            :fields="fields"
            :current-view="currentView"
            :entity-list="entityList"
            class="entity-list__view"
            @change-sort="changeSort"
            @mounted="onMountedViewComponent"
          >
            <template v-for="view of customViews" v-slot:[`view(${view.key})`]="scope">
              <slot :name="`view(${view.key})`" v-bind="scope"/>
            </template>
          </entity-list-view>

          <template v-if="isShowPagination">
            <!-- Pagination -->
            <div
              v-if="isPaginationType(ENTITY_LIST_PAGINATION_TYPES.BUTTONS)"
              class="entity-list__pagination"
            >
              <div
                v-if="isShowPaginationWarning || isShowClearFilterLink"
                class="pagination-help"
              >
                <div
                  v-if="isShowPaginationWarning"
                  class="pagination-help__text"
                  v-html="$vDict('entity_list.text_pagination_warning.text')"
                />
                <div
                  v-if="isShowClearFilterLink && !isShowPaginationWarning"
                  class="pagination-help__link"
                >
                  <b-link
                    class="el-show-more-link"
                    @click="entityList.clearFilters()"
                  >{{ $vDict('global.button_clear_filter.text') }}</b-link>
                </div>
              </div>

              <base-pagination
                :disabled="isLoadingContent"
                :current-page="entityList.currentPage"
                :total-rows="paginationTotalRows"
                :per-page="perPage"
                @change="onChangePage"
              />
            </div>

            <div
              v-else-if="isPaginationType(ENTITY_LIST_PAGINATION_TYPES.COLLAPSED)"
              class="entity-list__pagination pagination-collapsed"
            >
              <div class="pagination">
                <b-button
                  v-show="perPage < entityList.total && !showAllItems"
                  class="btn-collapse"
                  variant="primary"
                  @click="onShowMore"
                >
                  <div class="btn-collapse__text">
                    {{ $vDict('entity_list.button_show_more.text') }}
                  </div>
                  <div class="btn-collapse__icon">
                    <svg-icon
                      name="down"
                      size="14"
                    />
                  </div>
                </b-button>
                <b-button
                  v-show="entityList.total > 0 && showAllItems"
                  class="btn-collapse"
                  variant="primary"
                  @click="onShowLess"
                >
                  <div class="btn-collapse__text">
                    {{ $vDict('entity_list.button_show_less.text') }}
                  </div>
                  <div class="btn-collapse__icon">
                    <svg-icon
                      name="up"
                      size="14"
                    />
                  </div>
                </b-button>
              </div>
            </div>
          </template>
        </div>
        <div
          v-else-if="isEmptyList"
          class="entity-list__empty"
          key="empty"
        >
          <slot
            name="empty"
            :is-filters-filled="entityList.isFiltersFilled"
            :title="emptyListTitle"
            :clear-filter="entityList.clearFilters"
          >
            <widget-empty-list
              :title="emptyListTitle"
              :is-filters-filled="entityList.isFiltersFilled"
              @clear-filter="entityList.clearFilters()"
            />
          </slot>
        </div>
      </transition>
    </div>

    <!-- Fields Settings -->
    <modal-fields-setting
      ref="fieldsSettingsModal"
      :fields-sections="entityList.fieldsSections"
      :title="$vDict('entity_list.modal_field_settings_title.text')"
      :entity-list="entityList"
      @change="onChangeFieldset"
    />
  </div>
</template>

<script>
import WidgetEmptyList from '@/components/widgets/WidgetEmptyList';
import WidgetSearchEmpty from '@/components/widgets/WidgetEmptyList';
import EntityListHeader from '@/components/entity-list/EntityListHeader';
import EntityListView from "@/components/entity-list/EntityListView";
import BasePagination from "@/components/BasePagination";
import MixinCheckMobile from '@/mixins/MixinCheckMobile';
import { visibilityObserver } from '@/shared/utils';
import { checkNewItemsByTrigger, urlPageParams } from "@/components/entity-list/mixins";
import {
  ENTITY_LIST_VIEWS,
  ENTITY_LIST_PAGINATION_TYPES,
} from "@/config/enums";
import { getIndexedArray } from "@/shared/proxies";

const ModalFieldsSetting = () => import('@/components/entity-list/modals/ModalFieldsSetting');
const ViewsIndexedArray = getIndexedArray('key');

const MIN_CARD_WIDTH = 270;
const CARD_GAP = 10;

export default {
  name: 'entity-list',
  components: {
    WidgetEmptyList,
    EntityListHeader,
    EntityListView,
    WidgetSearchEmpty,
    BasePagination,
    ModalFieldsSetting,
  },
  mixins: [
    MixinCheckMobile,
    checkNewItemsByTrigger,
    urlPageParams,
  ],
  props: {
    fixedRequiredColumns: { type: Boolean, default: false },
    paginationType: { type: String, default: ENTITY_LIST_PAGINATION_TYPES.BUTTONS },
    customViews: { type: Array, default: Array },
    headerAlwaysShow: { type: Boolean, default: false },
    hideSort: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    entityList: { type: Object, default: Object },
    emptyTitle: { type: String, default: '' },
    loadImmediate: { type: Boolean, default: false },
    headerType: { type: String, default: '' },
    hideHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      ENTITY_LIST_VIEWS,
      ENTITY_LIST_PAGINATION_TYPES,

      //
      showAllItems: false,
      basePerPage: 0,
      minVisibleItemNumber: 0,
      isLoadingData: false,
      maxShowItems: null,
      visibleObserver: null,

      // check mobile
      isMobile: false,

      // ui
      loadedComponents: [],
      showViewContent: true,
      debounceCheckCurrentView: () => {},
    };
  },
  computed: {
    rootClasses() {
      const viewClass = this.entityList.viewMode ? `is-${this.entityList.viewMode}-view` : '';

      return [
        viewClass,
        {
          'is-loading': this.isLoadingContent,
          'is-main': this.isMainList,
          'is-user-section': this.entityList.selfClass.isUserSection,
          'entity-list--bordered': this.bordered,
        }
      ];
    },
    isLoadingContent() {
      return this.isLoadingData || this.entityList.isLoadingItems || !this.isComponentLoaded;
    },
    isShowViewLoading() {
      return this.isLoadingContent && (this.entityList.viewMode !== ENTITY_LIST_VIEWS.MAP || !this.isComponentLoaded);
    },
    isComponentLoaded() {
      return this.loadedComponents.includes(this.viewMode) || this.isNoItems;
    },
    isNoItems() {
      return this.items.length === 0 && this.entityList.viewMode !== ENTITY_LIST_VIEWS.MAP;
    },
    isEmptyList() {
      return !this.isLoadingContent && this.isNoItems && !this.currentView?.notCheckListStatus;
    },
    isShowFieldsSettings() {
      return this.entityList.config.showFieldsSettings && this.entityList.fieldsSections.length > 0;
    },
    isShowHeader() {
      if (this.hideHeader) {
        return false;
      }

      return !this.isEmptyList ||
        this.headerAlwaysShow ||
        this.isShowFieldsSettings ||
        this.entityList.isFiltersFilled ||
        this.entityList.lockedTotal > 0;
    },
    isShowPagination() {
      return this.items.length > 0 && !this.isLoadingContent && this.currentView?.isShowPagination;
    },
    isPaginationType() {
      return (type) => this.paginationType === type && this.perPage < this.entityList.total;
    },
    hasAllItems() {
      return this.entityList.hasAllItems;
    },
    listViews() {
      const allViews = [
        {
          key: ENTITY_LIST_VIEWS.LIST,
          title: this.$vDict('entity_list.tooltip_list_view.text'),
          icon: 'baseline-format_list_view',
          dataCy: 'button-list-view',
          isShowPagination: true,
        },
        {
          key: ENTITY_LIST_VIEWS.CARD,
          title: this.$vDict('entity_list.tooltip_cards_view.text'),
          icon: 'baseline-apps',
          dataCy: 'button-cards-view',
          isShowSortPicker: true,
          isShowPagination: true,
        },
        {
          key: ENTITY_LIST_VIEWS.MAP,
          title: this.$vDict('entity_list.tooltip_map_view.text'),
          icon: 'map-outlined',
          dataCy: 'button-map-view',
          showIfNoItems: true,
        },
        ...this.customViews.map(view => ({
          ...view,
          isCustomView: true,
        })),
      ];

      const views = allViews.filter(view => {
        let res = true;

        switch (view.key) {
          case ENTITY_LIST_VIEWS.LIST:
            if (!!this.entityList.cardView) {
              res = !this.isMobile;
            }
            break;
          case ENTITY_LIST_VIEWS.CARD:
            res = !!this.entityList.cardView;
            break;
          case ENTITY_LIST_VIEWS.MAP:
            res = this.entityList.config.showMapView;
            break;
          default:
        }

        return res;
      });

      return new ViewsIndexedArray(views);
    },
    currentView() {
      return this.listViews.findById(this.entityList.viewMode);
    },
    perPage() {
      return this.maxShowItems ?? this.entityList.perPage;
    },
    items() {
      let items;

      if (this.hasAllItems && !this.showAllItems) {
        const fr = (this.entityList.currentPage - 1) * this.perPage;
        let to = this.entityList.currentPage * this.perPage;

        if (to > this.entityList.total) {
          to = this.entityList.total;
        }

        items = this.entityList.items.slice(fr, to);
      }

      return items || this.entityList.items;
    },
    fields() {
      const fields = this.entityList.allCurrentFields;
      return fields.map(field => ({
        ...field,
        sortable: this.hideSort ? false : field.sortable,
      }));
    },
    offsetIndex() {
      return (this.entityList.currentPage - 1) * this.perPage;
    },
    paginationTotalRows() {
      return Math.min(this.entityList.total, 1000);
    },
    isShowPaginationWarning() {
      return this.paginationTotalRows < this.entityList.total &&
        this.entityList.currentPage === parseInt(this.paginationTotalRows / this.perPage);
    },
    sort() {
      return this.entityList.getCurrentSort();
    },
    viewMode() {
      return this.entityList.viewMode;
    },
    currentPage() {
      return this.entityList.currentPage;
    },
    emptyListTitle() {
      if (this.emptyTitle) {
        return this.emptyTitle;
      }

      let res = '';

      if (this.isWaitForNewItems &&
        typeof this.entityList.getWaitForItemsText === 'function') {
        res = this.entityList.getWaitForItemsText();
      } else if (typeof this.entityList.getEmptyListTitle === 'function') {
        res = this.entityList.getEmptyListTitle();
      }

      return res;
    },
    isLastPage() {
      return (this.entityList.currentPage * this.entityList.perPage) >= this.entityList.total;
    },
    isShowClearFilterLink() {
      return this.entityList.isFiltersFilled && this.isLastPage;
    },
    isMainList() {
      return this.entityList.config.isMain;
    },
  },
  watch: {
    listViews: {
      handler(views) {
        this.debounceCheckCurrentView(views);
      },
      immediate: true,
    },
    async currentPage(pageNumber) {
      if (pageNumber === 1) {
        this.minVisibleItemNumber = 0;
      }

      if (!this.isMainList && this.entityList.total >= this.entityList.perPage) {
        await this.setUrlPage(pageNumber);
      }
    },
    async viewMode(viewMode, oldViewMode) {
      if (!this.entityList.listDataInited) {
        return;
      }

      if (this.currentView.isCustomLoadItems) {
        this.showViewContent = true;
        this.isLoadingData = false;
        return;
      }

      this.loadedComponents = [];

      if (viewMode === ENTITY_LIST_VIEWS.MAP) {
        await this.$nextTick(() => {
          if (this.$refs.map) {
            this.$refs.map.updateOnce();
          }
        });

        return;
      }

      let reloadPage = null;

      this.reloadViewContent(async () => {
        if (!this.isMainList) {
          await this.$nextTick(() => {
            this.initMinVisibleItemNumber();
            this.initPerPage();

            if (this.items.length > 0) {
              reloadPage = this.checkNeedReloadPage();
            }
          });
        } else if (oldViewMode === ENTITY_LIST_VIEWS.MAP) {
          reloadPage = 1;
        }

        if (reloadPage !== null) {
          this.entityList.loadItems(reloadPage);
        }
      });
    },
  },
  created() {
    this.basePerPage = this.entityList.perPage;
    this.isLoadingData = true;

    if (this.isMainList) {
      document.body.classList.add('main-list');
    } else {
      this.checkUrlPage();
    }
  },
  mounted() {
    this.debounceCheckCurrentView = _.debounce((views = []) => {
      if (!views.includes(this.entityList.viewMode) && views[0]) {
        this.entityList.setViewMode(views[0].key);
      }
    }, 50);

    if (this.isMainList || this.loadImmediate) {
      this.loadMainList();
    } else if (this.$refs.cardBody) {
      this.visibleObserver = visibilityObserver(this.$refs.cardBody, (visible, obs) => {
        if (visible) {
          this.loadNotMainList();
          obs.unobserve(this.$refs.cardBody);
        }
      });
    }
  },
  beforeDestroy() {
    if (this.visibleObserver) {
      this.visibleObserver.disconnect();
    }
  },
  methods: {
    async loadMainList() {
      await this.entityList.initListData();
      this.entityList.loadItems();
      this.isLoadingData = false;
    },
    async loadNotMainList() {
      await this.entityList.initListData();
      this.initPerPage();
      this.entityList.loadItems();
      this.isLoadingData = false;
    },
    initPerPage() {
      const perPage = this.calcPerPage();

      if (this.hasAllItems) {
        this.maxShowItems = perPage;
      } else {
        this.entityList.perPage = perPage;
      }
    },
    async reloadViewContent(func) {
      if (this.showViewContent) {
        this.isLoadingData = true;
        this.showViewContent = false;
      }

      await func();

      setTimeout(() => {
        this.showViewContent = true;
        this.isLoadingData = false;
      }, 300);
    },
    checkNeedReloadPage() {
      // const isAllItemsVisible = this.hasAllItems ?
      //   this.perPage === this.items.length :
      //   this.items.length === this.entityList.total;
      const isAllItemsVisible = this.perPage === this.entityList.total &&
        this.items.length === this.entityList.total;

      if (isAllItemsVisible || !this.perPage) {
        return null;
      }

      const pageIndex = parseInt(this.minVisibleItemNumber / this.perPage);
      return (this.minVisibleItemNumber % this.perPage !== 0) ? pageIndex + 1 : pageIndex;
    },
    async onShowMore() {
      this.showAllItems = true;
    },
    onShowLess() {
      this.showAllItems = false;
    },
    onMountedViewComponent() {
      this.loadedComponents.push(this.viewMode);
    },
    showFieldsSettings() {
      this.$refs.fieldsSettingsModal.show();
    },
    onChangeFieldset(payload = {}) {
      const { value, alternativeListTypeId } = payload;

      this.reloadViewContent(() => {
        this.entityList.updateFieldset(value, alternativeListTypeId);
        this.entityList.loadItems();
      });
    },
    changeSort(sort) {
      this.reloadViewContent(() => {
        this.entityList.setSort(sort);
        this.entityList.loadItems(1);
      });
    },
    async changeView(view) {
      if (view !== ENTITY_LIST_VIEWS.MAP && view !== this.viewMode) {
        this.isLoadingData = true;
        this.showViewContent = false;
      }

      this.$nextTick(() => {
        this.entityList.setViewMode(view);
      });
    },
    async onChangePage(pageNumber) {
      const rect = this.$el.getBoundingClientRect();
      const scrollTop = rect.top + (window.pageYOffset || document.documentElement.scrollTop) - 50;

      setTimeout(() => {
        window.scrollTo(0, scrollTop);

        this.minVisibleItemNumber = 0;

        if (!this.hasAllItems) {
          this.entityList.loadItems(pageNumber);
        }
      });
    },
    // UI methods
    calcPerPage() {
      const { cardBody } = this.$refs;
      let perPage = this.basePerPage;

      if (cardBody && this.entityList.viewMode === ENTITY_LIST_VIEWS.CARD) {
        const style = getComputedStyle(cardBody);
        const bodyWidth = cardBody.clientWidth - parseInt(style.paddingLeft, 10) * 2;

        perPage = Math.floor((bodyWidth + CARD_GAP) / (MIN_CARD_WIDTH + CARD_GAP));
      }

      return perPage;
    },
    initMinVisibleItemNumber() {
      if (this.minVisibleItemNumber) {
        return;
      }

      this.minVisibleItemNumber = (this.perPage * (this.entityList.currentPage - 1)) + 1;
    },
    reload() {
      this.reloadViewContent(() => {
        this.entityList.loadItems(1);
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/components/entity-list';
</style>

<style lang="scss" scoped>
.el-show-more-link {
  font-family: var(--font-heading, $font-heading);
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--secondary);
}
</style>
