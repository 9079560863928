<template>
  <div class="entity-list-total-number">
    <div class="entity-list-total-number__body">
      <div
        key="total"
        class="entity-list-total-number__item"
        :class="{ 'visible': !isLoadingTotal }"
      >
        <span v-html="allTotalTexts.total" />
        <template v-if="allTotalTexts.allTotal">&nbsp;
          <span v-if="entityList.isFiltersFilled">
            <b-link
              v-b-tooltip.hover
              :title="$vDict('global.button_clear_filter.text')"
              class="clear-filter-link"
              v-html="allTotalTexts.allTotal"
              data-cy="all-total-clear-filter-link"
              @click="entityList.clearFilters()"
            />
          </span>
          <span
            v-else
            v-html="allTotalTexts.allTotal"
          />
        </template>
        <span
          v-if="allTotalTexts.cost"
          v-html="allTotalTexts.cost"
        />
        <v-spinner
          v-if="entityList.isLoadingAllTotalLongTime"
          size="16"
          class="all-total-spinner"
        />
      </div>
      <div
        v-if="lockedTotalData.total && isShowLockedTotal"
        key="locked-total"
        class="entity-list-total-number__item locked-total"
        :class="{
          'visible': !isLoadingTotal,
          'is-shown-locked': entityList.isShowLockedItems,
        }"
      >
        <svg-icon
          :name="lockedTotalData.icon"
          size="20"
          class="locked-total__icon"
        />
        <span
          v-b-tooltip.hover="{ boundary: 'window' }"
          :title="lockedTotalData.tooltip"
        >
          <b-form-checkbox
            class="locked-total__switch"
            data-cy="locked-total-toggle-link"
            switch
            :checked="entityList.isShowLockedItems"
            :data-toggle-state="entityList.isShowLockedItems ? 'shown' : 'hidden'"
            @change="onChangeLocked"
          />
        </span>
        <span
          v-html="lockedTotalData.text"
          class="locked-total__text"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from "@/shared/formatters";

export default {
  name: 'header-total',
  props: {
    entityList: { type: Object, default: Object },
    isLoadingData: { type: Boolean, default: true },
  },
  data() {
    return {
      isShowLockedTotal: true,
    };
  },
  computed: {
    supportEmail() {
      return this.$store.getters['Account/getSettingsValue']('client.support_email');
    },
    isLoadingTotal() {
      return this.entityList.isLoading || this.isLoadingData;
    },
    entityPluralText() {
      return this.entityList.selfClass.entityClass.getEntityPluralTypeText();
    },
    lockedTotalData() {
      const total = this.entityList.lockedTotal;
      let tooltipKey = 'locked_entities_hide';
      let icon = 'lock-outline';

      if (!this.entityList.isShowLockedItems) {
        tooltipKey = 'locked_entities_show';
        icon = 'lock-crossed';
      }

      return {
        text: this.$vDict(`entity_list.locked_entities_total.text`, {
          number: `<b data-cy="locked-total-number">${total}</b>`,
          entityPlural: this.entityPluralText,
        }),
        tooltip: this.$vDict(`entity_list.${tooltipKey}.text`, {
          entityPlural: this.entityPluralText,
        }),
        total,
        icon,
      };
    },
    allTotalTexts() {
      const totalValue = this.entityList.aggs?.value?.value;
      const res = {
        total: '',
        allTotal: '',
        cost: '',
      };

      // total
      res.total = `
        <b
          class="total-amount"
          data-cy="total-number"
        >${formatNumber(this.entityList.total ?? 0)}</b>
        ${this.entityPluralText}
      `;

      // all total
      if (this.entityList.allTotal && this.entityList.allTotal !== this.entityList.total) {
        res.allTotal = this.$vDict('global.text_out_of.text', {
          total: `<b data-cy="all-total-number">${formatNumber(this.entityList.allTotal)}</b>`,
        });
      }

      // total value (cost)
      if (totalValue) {
        const costTitle = this.$vDict('global.text_cost.text');
        const costValue = formatNumber(totalValue, { decimal: false });
        const currentSymbol = this.$store.getters['Account/userCurrencySymbol'].symbol;
        const totalAmount = `<b class="total-amount">${costValue}</b>`;
        const totalMountWithSymbol = currentSymbol?.symbolSide === 'left' ?
          `${currentSymbol} ${totalAmount}` :
          `${totalAmount} ${currentSymbol}`;

        res.cost = `. ${costTitle} ${totalMountWithSymbol}`;
      }

      return res;
    },
  },
  methods: {
    async onChangeLocked() {
      this.isShowLockedTotal = false;
      await this.entityList.toggleShowLockedItems();
      this.isShowLockedTotal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-list-total-number {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  display: flex;

  @include media-breakpoint-up(md) {
    padding-right: 32px;
    margin-right: auto;
    margin-bottom: 10px;
    width: auto;
    flex: 1 0 auto;
  }

  &__body {
    margin-left: -24px;
    margin-bottom: -4px;
    min-height: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    margin-left: 24px;
    margin-bottom: 12px;
    visibility: hidden;
    opacity: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 4px;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
      transition: opacity .3s;
    }
  }

  b {
    font-weight: bold;

    &.total-amount {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.locked-total {
  --text-color: var(--text-shaded);

  display: inline-flex;
  align-items: center;

  &.is-shown-locked {
    --text-color: var(--secondary);
  }

  &__icon {
    color: var(--text-color);
    display: inline-block;
    margin-top: -4px;
    margin-right: 4px;
    vertical-align: middle;
  }

  &__text {
    color: var(--text-color);
    margin-left: 6px;
  }
}

.clear-filter-link {
  color: var(--link-secondary-hover-color);
  text-decoration: underline;

  &:hover {
    color: var(--secondary);
    //text-decoration: none
  }
}

.all-total-spinner {
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 2px;
}
</style>
