<template>
  <ul class="collapsible-list">
    <slot
      v-for="item in items"
      :name="`li(${item.key})`"
      :item="item"
    >
      <li
        :is="item.notCollapsible ? 'li' : 'collapsible-list-item'"
        :key="item.key"
        :item="item"
        :class="item.class"
        class="collapsible-list__item"
        @shown="$emit('shown', $event)"
      >
        <loading
          v-if="loadingItems[item.key]"
          height="150"
        />
        <slot
          v-else-if="hasSlot(`item(${item.key})`)"
          :name="`item(${item.key})`"
          :item="item"
        />
        <slot
          v-else
          :item="item"
        />
      </li>
    </slot>
  </ul>
</template>

<script>
import CollapsibleListItem from "@/components/collapsible-list/CollapsibleListItem";
import MixinSlots from "@/mixins/MixinSlots";

export default {
  name: 'collapsible-list',
  components: {
    CollapsibleListItem,
  },
  mixins: [
    MixinSlots,
  ],
  props: {
    loadingItems: { type: Object, default: Object },
    items: { type: Array, default: Array },
  },
};
</script>

<style lang="scss" scoped>
.collapsible-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
