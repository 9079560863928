import {
  USER_ADDONS,
  ENTITY_TYPES,
} from "@/config/enums";

export const modulesPriority = [
  USER_ADDONS.PROJECTS,
  USER_ADDONS.PROJECT_MATERIALS,
  USER_ADDONS.TENDERS,
  USER_ADDONS.CRM,
  USER_ADDONS.COMPANY_ANALYTICS,
  USER_ADDONS.EXPORT_TO_EXCEL,
  USER_ADDONS.USAGE_STATISTICS,
  USER_ADDONS.LIVESTAT_RSM_EDITION,
  USER_ADDONS.LIVESTAT_RSM_EDITION_W_EXPORT,
  USER_ADDONS.LIVESTAT_GYB_SALES,
  USER_ADDONS.LIVESTAT_GYB_PURCHASING,
  USER_ADDONS.LIVESTAT_GYB_MARKETING,
  USER_ADDONS.LIVESTAT_GYB_TENDER,
  USER_ADDONS.LIVESTAT_GYB_STRATEGY,
  USER_ADDONS.LIVESTAT_GYB_PQ,
  USER_ADDONS.LIVESTAT_MARKET_INSIGHT_SMALL,
  USER_ADDONS.LIVESTAT_MARKET_INSIGHT_MEDIUM,
  USER_ADDONS.LIVESTAT_MARKET_INSIGHT_LARGE,
  USER_ADDONS.LIVESTAT_MARKET_INSIGHT_SMALL_WITH_MATERIAL,
  USER_ADDONS.LIVESTAT_MARKET_INSIGHT_MEDIUM_WITH_MATERIAL,
  USER_ADDONS.LIVESTAT_MARKET_INSIGHT_LARGE_WITH_MATERIAL,
  USER_ADDONS.LIVESTAT_LIVESTAT_MATERIALS,
  USER_ADDONS.WEBHOOKS,
  USER_ADDONS.RELEASE_NOTES,
  USER_ADDONS.VORTAL_CONNECT,
  USER_ADDONS.EXTENDED_CONTACTS,
  USER_ADDONS.API_CLIENT_INFO,
];

export const entityModulesIds = {
  [ENTITY_TYPES.CLIENT]: {
    main: [],
    fixed: [
      USER_ADDONS.EXTENDED_CONTACTS,
    ],
  },
  [ENTITY_TYPES.SUBSCRIPTION]: {
    main: [
      USER_ADDONS.TENDERS,
      USER_ADDONS.CRM,
      USER_ADDONS.PROJECT_MATERIALS,
      USER_ADDONS.PROJECTS,
      USER_ADDONS.COMPANY_ANALYTICS,
      USER_ADDONS.EXPORT_TO_EXCEL,
      USER_ADDONS.USAGE_STATISTICS,
      USER_ADDONS.LIVESTAT_RSM_EDITION,
      USER_ADDONS.LIVESTAT_RSM_EDITION_W_EXPORT,
      USER_ADDONS.LIVESTAT_GYB_SALES,
      USER_ADDONS.LIVESTAT_GYB_PURCHASING,
      USER_ADDONS.LIVESTAT_GYB_MARKETING,
      USER_ADDONS.LIVESTAT_GYB_TENDER,
      USER_ADDONS.LIVESTAT_GYB_STRATEGY,
      USER_ADDONS.LIVESTAT_GYB_PQ,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_SMALL,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_MEDIUM,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_LARGE,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_SMALL_WITH_MATERIAL,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_MEDIUM_WITH_MATERIAL,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_LARGE_WITH_MATERIAL,
    ],
    fixed: [],
  },
  [ENTITY_TYPES.USER_PROFILE]: {
    main: [
      USER_ADDONS.TENDERS,
      USER_ADDONS.CRM,
      USER_ADDONS.COMPANY_ANALYTICS,
      USER_ADDONS.EXPORT_TO_EXCEL,
      USER_ADDONS.USAGE_STATISTICS,
      USER_ADDONS.LIVESTAT_RSM_EDITION,
      USER_ADDONS.LIVESTAT_RSM_EDITION_W_EXPORT,
      USER_ADDONS.LIVESTAT_GYB_SALES,
      USER_ADDONS.LIVESTAT_GYB_PURCHASING,
      USER_ADDONS.LIVESTAT_GYB_MARKETING,
      USER_ADDONS.LIVESTAT_GYB_TENDER,
      USER_ADDONS.LIVESTAT_GYB_STRATEGY,
      USER_ADDONS.LIVESTAT_GYB_PQ,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_SMALL,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_MEDIUM,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_LARGE,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_SMALL_WITH_MATERIAL,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_MEDIUM_WITH_MATERIAL,
      USER_ADDONS.LIVESTAT_MARKET_INSIGHT_LARGE_WITH_MATERIAL,
    ],
    fixed: [
      USER_ADDONS.WEBHOOKS,
      USER_ADDONS.RELEASE_NOTES,
      USER_ADDONS.VORTAL_CONNECT,
      USER_ADDONS.API_CLIENT_INFO,
    ],
  },
};
